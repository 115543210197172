import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CombinedProvider } from "./context/CombinedProvider";
import useExternalCss from "./shared/useExternalCss";
import { ConfigProvider } from "antd";
import AntdConfig from "./utils/antdConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppWrapper = () => {
  useExternalCss(`/styles.css`);
  return <App />;
};

root.render(
  <BrowserRouter>
    <CombinedProvider>
      <ConfigProvider theme={AntdConfig}>
        <AppWrapper />
      </ConfigProvider>
    </CombinedProvider>
  </BrowserRouter>
);

reportWebVitals();
