import { useState, useEffect } from "react";
import { useAnonymous } from "../context/anonymousContext"; // Assuming anonymous context is already defined
import { BASE_URL, PROJECT_ID } from "../constants/authConst";

const useFetchFormDetails = () => {
  const token = sessionStorage.getItem("token");
  const { setAnonymous, setSlug, setSteps, setIsAccountValidation } =
    useAnonymous();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFormDetails = async () => {
      if (!token) return;
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/forms`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const form = data.data.find((item) => item.slug === PROJECT_ID);

          const validation = data.data.find(
            (item) => item.include_otp_validation === "Yes"
          );

          if (form) {
            setSlug(form.slug);
            setAnonymous(form.anonymous === 1);
            setIsAccountValidation(
              validation.include_account_validation === "Yes"
            );
            const stepSlugs = form.steps.map((step) => step.step_slug);
            setSteps(stepSlugs);
          } else {
            setError("Form not found.");
          }
        } else {
          setError("Failed to retrieve form data. Please try again.");
        }
      } catch (error) {
        setError(
          "An error occurred while retrieving the form data. Please try again."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchFormDetails();
  }, [token, setAnonymous, setSlug, setSteps]);

  return { loading, error };
};

export default useFetchFormDetails;
